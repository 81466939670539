import React from 'react'
import { Link } from 'react-router-dom'

const DefaultEmptyResults = function () {
  return (
    <div className="search-results__empty">
      <div className="search-results__message">
        <p className="body-copy-2">
          Sorry! Although we have thousands of learning resources on Bendable, we weren’t able to
          find what you were looking for.
        </p>
        <p className="body-copy-2">
          If you’ve done a filtered search, you might try taking off one or more of the filters.
          For example, if you’ve searched for in-person learning options only, try including online courses, as well.
        </p>
        <p className="body-copy-2">
          If you still don’t find anything of interest, try exploring the interest areas on the
          {' '}
          <Link to="/explore">Discover</Link>
          {' '}
          page to get an idea of the kinds of learning resources we have on Bendable.
        </p>
      </div>

      {/* <div className="search-results__message">
        <p className="body-copy-2">
          Or browse the topics on the Explore page to get an idea of the
          {' '}
          kinds of learning resources we have on Bendable.&nbsp;
          <img
            className="search-results__inline-explore"
            src={ExploreImg}
            alt="Explore icon"
          />
        </p>
      </div> */}

    </div>
  )
}

export default DefaultEmptyResults
